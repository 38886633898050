import classNames from "classnames"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import GetYourFreeCyberRiskAssessment from "../../images/GetYourFreeCyberRiskAssessment.svg"
import LoadTesting from "../../images/LoadTesting.svg"
import WebApplicationPenetrationTesting from "../../images/WebApplicationPenetrationTesting.svg"
import WebTotemAwareness from "../../images/WebTotemAwareness.svg"
import WordPressSecurityAudit from "../../images/WordPressSecurityAudit.svg"
import WordPressSecurityPlugin from "../../images/WordPressSecurityPlugin.svg"
import CloseIcon from "../../images/close.svg"
import LogoWhite from "../../images/logo_white.svg"
import MenuBlackIcon from "../../images/menu_black.svg"
import MenuLightIcon from "../../images/menu_light.svg"
import "./styles.css"

import { Link } from "gatsby-plugin-intl"
import scrollTo from "gatsby-plugin-smoothscroll"
import Popup from "reactjs-popup"
import { useAuthCheck } from "../../hooks/useAuthCheck"
import { useAnalytics } from "../../hooks/useTracking"
import Logo from "../../images/logo.svg"
import { useTranslation } from "../../utils"

const Header = ({ theme = "light" }) => {
  const { t, locale } = useTranslation()
  const [sidebar, setSideBar] = useState(false)
  const { link, linkText, authed } = useAuthCheck()
  const [serviceMenu, setServiceMenu] = useState(false)
  const analytics = useAnalytics()

  const headerClassList = classNames("header", `header--${theme}`)
  const sidebarClassList = classNames(
    "header__sidebar",
    `header__sidebar--${theme}`
  )

  const toScoringForm = () => {
    analytics.freeSecurityTestClicked()
    setSideBar(false)
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/ru" &&
      window.location.pathname !== "/pl"
    ) {
      navigate("/" + locale + "/scoring")
    } else {
      scrollTo("#scoring-form")
    }
  }

  const toggleMenu = () => setSideBar(!sidebar)

  const handleAcc = () => {
    if (!serviceMenu) analytics.showServices()
    setServiceMenu(!serviceMenu)
  }

  return (
    <div className={headerClassList}>
      <header className="header__inner container">
        <div onClick={toggleMenu}>
          {theme === "light" ? (
            <img className="header__burger" src={MenuBlackIcon} alt="menu" />
          ) : (
            <img className="header__burger" src={MenuLightIcon} alt="menu" />
          )}
        </div>

        <Link to="/" className="header__logo">
          {theme === "light" ? (
            <img src={Logo} alt="Logo" />
          ) : (
            <img src={LogoWhite} alt="Logo" />
          )}
        </Link>
        <Navigation />
        <button
          className="wt-button wt-button--secondary header__score"
          onClick={toScoringForm}
        >
          {t("Get free security test")}
        </button>
        <a
          onClick={
            authed ? analytics.clickGoToDashboard : analytics.clickSignIn
          }
          href={link}
          className="header__btn-login wt-button wt-button--primary"
        >
          {t(linkText)}
        </a>
      </header>

      {sidebar && (
        <div className={sidebarClassList}>
          <div className="container">
            <img
              src={CloseIcon}
              alt="close"
              className="header__close"
              onClick={toggleMenu}
            />
          </div>
          <nav className="header__sidebar-nav">
            <div className="acc-service">
              <div className="acc-service__wrapper">
                <div className="acc-service__title" onClick={handleAcc}>
                  {t("Services")}
                </div>
                {serviceMenu && (
                  <div className="acc-service__list">
                    <Link
                      onClick={analytics.clickGoToAwareness}
                      activeClassName="active"
                      to="/awareness"
                      className="acc-service__item-link wt-text"
                    >
                      WebTotem Awareness
                    </Link>
                    <Link
                      onClick={analytics.clickGoToLoadTesting}
                      activeClassName="active"
                      to="/load"
                      className="acc-service__item-link wt-text"
                    >
                      Load testing
                    </Link>
                    <Link
                      onClick={analytics.clickGoToWordpressPlugin}
                      activeClassName="active"
                      to="/wordpress"
                      className="acc-service__item-link wt-text"
                    >
                      WordPress Security Plugin
                    </Link>
                    <Link
                      onClick={analytics.clickGoToWordpressAudit}
                      activeClassName="active"
                      to="/wordpress-security-audit"
                      className="acc-service__item-link wt-text"
                    >
                      WordPress Security Audit
                    </Link>
                    <Link
                      onClick={analytics.clickGoToWordpressPentest}
                      activeClassName="active"
                      to="/pentest"
                      className="acc-service__item-link wt-text"
                    >
                      Web-Application Penetration testing
                    </Link>
                    <Link
                      onClick={() => analytics.clickGoToService("/scoring")}
                      activeClassName="active"
                      to="/scoring"
                      className="acc-service__item-link wt-text"
                    >
                      Get your free Cyber Risk Assessment
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="header__link-wrapper">
              <a
                onClick={analytics.clickGoToBlog}
                href="https://wtotem.com/blog/"
                target="_blank"
                rel="noopener"
              >
                {t("Blog")}
              </a>
            </div>
            <div className="header__link-wrapper">
              <Link
                onClick={analytics.clickGoToPricing}
                activeClassName="active"
                to="/pricing"
              >
                {t("Pricing")}
              </Link>
            </div>
            <div className="header__link-wrapper">
              <Link
                onClick={analytics.clickGoToTeam}
                activeClassName="active"
                to="/team"
              >
                {t("Team")}
              </Link>
            </div>
            <div className="header__link-wrapper">
              <Link
                onClick={analytics.clickGoToHowItWorks}
                activeClassName="active"
                to="/docs"
              >
                {t("How it works?")}
              </Link>
            </div>
            <div className="header__link-wrapper">
              <div className="header__btn-wrapper">
                <button
                  className="wt-button wt-button--secondary"
                  onClick={toScoringForm}
                >
                  {t("Get free security test")}
                </button>
              </div>
            </div>
          </nav>
        </div>
      )}
    </div>
  )
}

Header.propTypes = {
  theme: PropTypes.oneOf(["dark", "light"]),
}

const Navigation = () => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  return (
    <nav className="header__navigation">
      <ul>
        <li>
          <SubNavigation />
        </li>
        <li>
          <Link
            title="Team"
            onClick={analytics.clickGoToTeam}
            to="/team"
            activeClassName="active"
            className="header__navigation--item"
          >
            {t("Team")}
          </Link>
        </li>
        <li>
          <a
            onClick={analytics.clickGoToBlog}
            title="Blog"
            href="https://wtotem.com/blog/"
            target="_blank"
            rel="noopener"
            className="header__navigation--item"
          >
            {t("Blog")}
          </a>
        </li>
        <li>
          <Link
            onClick={analytics.clickGoToPricing}
            title="Pricing"
            to="/pricing"
            activeClassName="active"
            className="header__navigation--item"
          >
            {t("Pricing")}
          </Link>
        </li>
        <li>
          <Link
            onClick={analytics.clickGoToHowItWorks}
            title="How it works?"
            to="/docs"
            activeClassName="active"
            className="header__navigation--item"
          >
            {t("How it works?")}
          </Link>
        </li>
      </ul>
    </nav>
  )
}

const SubNavigation = () => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  return (
    <Popup
      onOpen={analytics.showServices}
      trigger={open => {
        return (
          <div
            title="Services"
            className={`header__navigation--item wt-text ${
              open ? "wt-text--bold" : "wt-text"
            }`}
          >
            {t("Services")}
          </div>
        )
      }}
      position="bottom center"
      on={["hover", "focus"]}
      arrow="bottom center"
      contentStyle={{
        width: "auto",
        padding: "0",
        border: "none",
        boxShadow:
          "0px 100px 80px rgba(90, 90, 90, 0.17), 0px 36.5016px 29.2013px rgba(90, 90, 90, 0.117297), 0px 17.7209px 14.1767px rgba(90, 90, 90, 0.0945694), 0px 8.6871px 6.94968px rgba(90, 90, 90, 0.0754306), 0px 3.43489px 2.74791px rgba(90, 90, 90, 0.0527031)",
        borderRadius: "7px",
        animationName: "opacity",
        animationDuration: ".3s",
        animationTimingFunction: "ease",
      }}
      arrowStyle={{
        display: "none",
      }}
    >
      <div>
        <div className="header-popup">
          <div className="header-popup__row">
            <div className="header-popup__title">
              {t("Wordpress Solutions")}
            </div>
            <div className="header-popup__title">{t("Web Applications")}</div>
          </div>
          <div className="header-popup__row">
            <Link
              onClick={analytics.clickGoToWordpressPlugin}
              to="/wordpress"
              className="header-popup__item"
            >
              <div className="wt-margin-right-20">
                <img src={WordPressSecurityPlugin} alt="Icon" />
              </div>
              <div className="header-popup__item-content">
                <h4 className="header-popup__item-title wt-text--line-26 wt-text wt-text--size-300">
                  {t("WordPress Security Plugin")}
                </h4>
              </div>
            </Link>
            <Link
              onClick={analytics.clickGoToWordpressPentest}
              to="/pentest"
              className="header-popup__item"
            >
              <div className="wt-margin-right-20">
                <img src={WebApplicationPenetrationTesting} alt="Icon" />
              </div>
              <div className="header-popup__item-content">
                <h4 className="header-popup__item-title wt-text--line-26 wt-text wt-text--size-300">
                  {t("Web - Application Penetration testing")}
                </h4>
              </div>
            </Link>
          </div>
          <div className="header-popup__row">
            <Link
              onClick={analytics.clickGoToWordpressAudit}
              to="/wordpress-security-audit"
              className="header-popup__item"
            >
              <div className="wt-margin-right-20">
                <img src={WordPressSecurityAudit} alt="Icon" />
              </div>
              <div className="header-popup__item-content">
                <h4 className="header-popup__item-title wt-text--line-26 wt-text wt-text--size-300">
                  {t("WordPress Security Audit")}
                </h4>
              </div>
            </Link>
            <Link
              onClick={() => analytics.clickGoToService("/scoring")}
              to="/scoring"
              className="header-popup__item"
            >
              <div className="wt-margin-right-20">
                <img src={GetYourFreeCyberRiskAssessment} alt="Icon" />
              </div>
              <div className="header-popup__item-content">
                <h4 className="header-popup__item-title wt-text--line-26 wt-text wt-text--size-300">
                  {t("Get your free Cyber Risk Assessment")}
                </h4>
              </div>
            </Link>
          </div>
          <div className="header-popup__row">
            <div className="header-popup__title">
              {t("Additional services")}
            </div>
          </div>
          <div className="header-popup__row">
            <Link
              onClick={analytics.clickGoToAwareness}
              to="/awareness"
              className="header-popup__item"
            >
              <div className="wt-margin-right-20">
                <img src={WebTotemAwareness} alt="Icon" />
              </div>
              <div className="header-popup__item-content">
                <h4 className="header-popup__item-title wt-text--line-26 wt-text wt-text--size-300">
                  {t("WebTotem Awareness")}
                </h4>
              </div>
            </Link>
            <Link
              onClick={analytics.clickGoToLoadTesting}
              to="/load"
              className="header-popup__item"
            >
              <div className="wt-margin-right-20">
                <img src={LoadTesting} alt="Icon" />
              </div>
              <div className="header-popup__item-content">
                <h4 className="header-popup__item-title wt-text--line-26 wt-text wt-text--size-300">
                  {t("Load testing")}
                </h4>
              </div>
            </Link>
          </div>
          <div className="header-popup__gray-row">
            <Link
              onClick={analytics.clickGoToTeam}
              to="/team"
              className="header-popup__link"
            >
              {t("Meet the Team")}
            </Link>
            <a
              onClick={analytics.clickGoToBlog}
              href="https://wtotem.com/blog/"
              className="header-popup__link"
              target="_blank"
              rel="noopener"
            >
              {t("Blog")}
            </a>
            <Link
              onClick={analytics.clickGoToContacts}
              to="/contacts"
              className="header-popup__link"
            >
              {t("Contacts")}
            </Link>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default Header
