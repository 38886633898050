import React from "react"
import "./styles.css"
import { Link } from "gatsby-plugin-intl"
import Logo from "../../images/logo.svg"
import LogoShort from "../../images/logo_short.svg"
import FacebookIcon from "../../images/facebook.svg"
import LinkedInIcon from "../../images/linkedin.svg"
import YoutubeIcon from "../../images/youtube.svg"
import VisaIcon from "../../images/visa.svg"
import MasterCardIcon from "../../images/master-card.svg"
import { getCurrentYear, useTranslation } from "../../utils"
import { useAnalytics } from "../../hooks/useTracking"

const Footer = () => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  return (
    <footer className="footer">
      <div className="container">
        <div className="wt-divider wt-margin-bottom-105 display-sm-none display-xs-none" />

        <div
          className="row wt-padding-top-50"
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <div className="col-3 col-sm-12 order-sm-1 order-md-0 ">
            <div className="display-sm-none display-xs-none">
              <img src={Logo} alt="logo" />
              <span className="footer__tagline">
                {t("Your personal web application security assistant")}
              </span>
            </div>
            {/*<Languages />*/}
          </div>
          <div className="col-3 wt-flex-column display-sm-none display-xs-none">
            <span className="footer__menu-title">{t("Services")}</span>
            <Link
              onClick={analytics.clickGoToAwareness}
              to="/awareness"
              className="footer__link"
            >
              {t("WebTotem Awareness")}
            </Link>
            <Link
              onClick={analytics.clickGoToLoadTesting}
              to="/load"
              className="footer__link"
            >
              {t("Load testing")}
            </Link>
            <Link
              onClick={analytics.clickGoToWordpressPlugin}
              to="/wordpress"
              className="footer__link"
            >
              {t("WordPress Security Plugin")}
            </Link>
            <Link
              onClick={analytics.clickGoToWordpressAudit}
              to="/wordpress-security-audit"
              className="footer__link"
            >
              {t("WordPress Security Audit")}
            </Link>
            <Link
              onClick={analytics.clickGoToWordpressPentest}
              to="/pentest"
              className="footer__link"
            >
              {t("Web-Application Penetration Testing")}
            </Link>
          </div>
          <div className="col-3 col-sm-12 wt-flex-column">
            <span className="footer__menu-title">{t("About us")}</span>
            <Link
              onClick={analytics.clickGoToTeam}
              to="/team"
              className="footer__link"
            >
              {t("Meet the Team")}
            </Link>
            <a
              onClick={analytics.clickGoToBlog}
              href="https://wtotem.com/blog/"
              className="footer__link"
            >
              {t("Blog")}
            </a>
            <Link
              onClick={analytics.clickGoToFAQ}
              to="/faq"
              className="footer__link"
            >
              {t("FAQ")}
            </Link>
          </div>
          <div className="col-3 wt-flex-column display-sm-none display-xs-none">
            <span className="footer__menu-title">{t("Contacts")}</span>
            <span className="footer__link">
              {t("Feel free to get in touch with us.")}
            </span>
            <a
              href="mailto:info@wtotem.com"
              className="footer__link wt-text wt-text--dark-gray"
            >
              info@wtotem.com
            </a>
            <div className="wt-flex">
              <a
                onClick={analytics.clickGoToFB}
                href="https://www.facebook.com/webtotem"
                target="_blank"
                aria-label="Facebook"
                rel="noopener"
                className="wt-margin-right-10"
              >
                <img src={FacebookIcon} alt="Facebook icon" />
              </a>
              <a
                onClick={analytics.clickGoToLinkedIn}
                href="https://www.linkedin.com/company/wtotem/"
                target="_blank"
                aria-label="Linkedin"
                rel="noopener"
                className="wt-margin-right-10"
              >
                <img src={LinkedInIcon} alt="Linkedin icon" />
              </a>
              <a
                onClick={analytics.clickGoToYT}
                href="https://www.youtube.com/channel/UCD-n_NIXTOmw4Nm-LcmW1XA/featured"
                target="_blank"
                aria-label="Youtube"
                rel="noopener"
              >
                <img src={YoutubeIcon} alt="Youtube icon" />
              </a>
            </div>
          </div>
        </div>

        <div
          className="wt-divider wt-margin-top-40"
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-easing="ease"
        />

        <div
          className="footer__bottom"
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <div className="footer__block-left">
            <img
              src={LogoShort}
              alt="Logo"
              className="display-none display-lg-none display-md-none wt-margin-bottom-25"
            />
            <span className="display-none display-lg-none display-md-none">
              {t("Your personal web application security assistant")}
            </span>
            <h5>
              © 2017-{getCurrentYear()} -{" "}
              {t("LLC WebTotem, All Rights Reserved")}
            </h5>
          </div>
          <div className="footer__block-right footer__block-right_links wt-flex">
            <Link
              onClick={analytics.clickGoToPP}
              to="/privacy"
              className="footer__link footer__link_blue"
            >
              {t("Privacy note")}
            </Link>
            <Link
              onClick={analytics.clickGoToTerms}
              to="/terms"
              className="footer__link footer__link_blue"
            >
              {t("Terms of use")}
            </Link>
          </div>
          <div className="footer__block-right footer__block-right_cards wt-flex">
            <img
              className="wt-margin-right-10 display-sm-none display-xs-none"
              src={VisaIcon}
              alt="Visa"
            />
            <img
              className="display-sm-none display-xs-none"
              src={MasterCardIcon}
              alt="master card"
            />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
